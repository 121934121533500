import {UnitSystem} from '@unitSystem/models';

import {UserRoleType} from '../directories';
import {CrewStatuses} from '../expense';
import {ConditionalStatus} from '../general';
import {PaginationDto} from '../response';

export enum UserDownloadFileStatus {
  Created = 1,
  Ready,
  Failed
}

interface SocialUserDto {
  readonly id: string;
  readonly username: string;
}

export interface UserSocialAuth {
  readonly facebook: SocialUserDto | null;
  readonly google: SocialUserDto | null;
}

export class User {
  constructor(
    public readonly id: number,
    public readonly firstName: string,
    public readonly lastName: string,
    public readonly email: string,
    public readonly roleName: string,
    public readonly roleType: UserRoleType,
    public readonly passwordSet: boolean,
    public readonly percentAccountCompletion: number,
    public readonly lastUpdated: string,
    public readonly status: CrewStatuses,
    public readonly avatar: string | null,
    public readonly needChangePassword: boolean,
    public readonly phone: UserPhoneNumber,
    public readonly lastTimeUpdated: number,
    public readonly newsletterSubscription: ConditionalStatus,
    public readonly socialAuth: UserSocialAuth,
    public units: UnitSystem,
    public readonly usefulInfo: UserUsefulInfo,
  ) {
  }
}

export interface UserUsefulInfo {
  readonly firstLogin: boolean;
}

export class UserPhoneNumber {
  constructor(
    public readonly code: string,
    // tslint:disable-next-line:variable-name
    public readonly number: string,
  ) {

  }
}

export class UserUnitSystemPayload {
  constructor(public readonly unitsSystem: UnitSystem) {
  }
}

export class UserSubscribePayload {
  constructor(
    public readonly subscribe: ConditionalStatus,
  ) {
  }
}

export class UserTokenSubscribePayload extends UserSubscribePayload {
  constructor(
    subscribe: ConditionalStatus,
    public readonly token: string,
  ) {
    super(subscribe);
  }
}

export class UserDownloadDocumentsPayload {
  constructor(
    public readonly page: number,
    public readonly limit: number,
  ) {
  }
}

export class UserDocumentListDto {
  constructor(
    public readonly queueList: UserDocumentItemDto[],
    public readonly pagination: PaginationDto,
  ) {
  }
}

export class UserDocumentItemDto {
  constructor(
    public readonly  id: number,
    public readonly reportType: string,
    public readonly viewed: boolean,
    public readonly downloaded: boolean,
    public readonly fileName: string,
    public readonly originFilename: string,
    public readonly dateCreate: number,
    public readonly dateUpdate: number,
    public readonly status: UserDownloadFileStatus,
  ) {
  }
}

export class ChangeUserDocumentStatusPayload {
  constructor(
    public readonly queueId: number[],
  ) {
  }
}

export class UserDownloadDocument {
  constructor(
    public readonly status: UserDownloadFileStatus,
    public readonly name: string,
    public readonly additionalClass: string,
  ) {
  }
}

export interface UserFleetShowData {
  readonly create: boolean;
  readonly header: boolean;
}

export interface UserFleetData {
  readonly defaultFleetId: number | null;
  readonly hasFleet: boolean;
  readonly show: UserFleetShowData;
}
